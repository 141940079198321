import React from 'react';
import { getRequestDemoLink } from '../helpers/emails'

function HelloWorld() {
  return (
    <div style={styles.helloWorld}>Hi! Welcome to Level<br /> ♥️   A TimBiz Production<br />
    </div>
  );
}

const styles = {
  helloWorld: {
    margin: '20px',
  }
}

export default HelloWorld;
