import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Social from './social'
import { GetContent } from '../helpers/content.js'
import EmailSignup from './email-signup.js'
import Button from 'react-bootstrap/Button';
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
  APP_MAX_WIDTH,
} from '../styles/global'
import { track } from '../helpers/analytics'

function Hero(info) {

  const CONTENT = GetContent();
  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  function openDemo() {
    track('Clicked - See Demo')
    window.open("https://demo.trylevel.app/?source=landing");
  }

  return (
    <div style={styles.heroContainer}>
      <h2 style={styles.heroTitle}>{ CONTENT["hero"]["title"] }</h2>
      <p style={styles.subTitle}>
       { CONTENT["hero"]["subTitle"] }
      </p>
      {
        <div style={styles.inputContainer}>
          <EmailSignup />
        </div>
      }
      { /*
      <Button
        id="footer-cta"
        onClick={() => (openDemo())}>
          See the Demo
      </Button> */ }
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let styleMods = {};
  let heroContainer, heroTitle, subTitle, inputContainer;
  if (isDesktop) {
    heroContainer = {
      maxWidth: '1000px',
      padding: `0px ${APP_PADDING_DESKTOP}`,
      margin: "100px 0px 100px 0px",
    };
    heroTitle = {
    };
    subTitle = {
    };
  }

  if (isTablet) {
    heroContainer = {
      padding: `0px ${APP_PADDING_TABLET}`,
      margin: "140px 0px 200px 0px",
      maxWidth: '88%',
    };
    heroTitle = {
    };
    subTitle = {
    };
  }

  if (isMobile) {
    heroContainer = {
      padding: `0px ${APP_PADDING_MOBILE}`,
      margin: "120px 0px 200px 0px",
    };
    heroTitle = {
      fontSize: "30px",
    };
    subTitle = {
      margin: "20px 20px",
    };
    inputContainer = {
    }
  }

  let styles = {
    heroContainer: {
      flexGrow: 1,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
      ...heroContainer,
    },
    subTitle: {
      fontWeight: "400",
      fontSize: "20px",
      textAlign: "center",
      maxWidth: "820px",
      ...subTitle,
    },
    heroTitle: {
      fontWeight: "700",
      fontSize: "44px",
      textAlign: "center",
      ...heroTitle,
    },
    inputContainer: {
      ...inputContainer,
    }
  }
  return styles;
}

export default Hero;
