import React, { useEffect, useRef } from "react";
import useWindowSize from "../helpers/window-size-hook";
import ScrollPositionContext from "../helpers/scroll-position-context";
import useScrollPosition from "../helpers/scroll-position-hook";
import WindowSizeContext from "../helpers/window-size-context";
import getScreenSize from "../helpers/get-screen-size";
import { ChakraProvider, Box, Center, Button, Heading, AspectRatio, Text, VStack, extendTheme } from "@chakra-ui/react";
import EmailSignup from "../components/email-signup.js";
import Typed from "react-typed";
import Typing from "react-typing-animation";
import Footer from "../components/footer";
import "./trusted-network-landing.css";
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { useNavigate } from "@reach/router";
import { track } from "../helpers/analytics";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
});
const theme = extendTheme({ breakpoints });

const typeSpeed = 20;
const backSpeed = 30;
const backDelay = 3000;

function getTimeLength(strings) {
  return strings.reduce((accum, curr) => {
    return accum + curr.length * typeSpeed + curr.length * backSpeed + backDelay;
  }, 0);
}

let type1Strings = ["sales", "investors", "feedback", "hires"];

let type2Strings = ["friends", "team", "advisors", "softball team"];

let type1Time = getTimeLength(type1Strings);
let type2Time = getTimeLength(type2Strings);
let totalTime = type1Time + type2Time;
console.log(type1Time);
console.log(type2Time);
console.log(totalTime);

function TrustedNetworkLandingPage() {
  const navigate = useNavigate();
  const typer1 = useRef();
  const typer2 = useRef();
  const placeHolderRef = useRef();
  const scrollY = useScrollPosition();
  const windowSize = useWindowSize();

  function onScheduleDemo() {
    track("Clicked - Schedule Demo");
    window.open("https://calendly.com/vladimirkorshin/15min");
  }

  useEffect(() => {
    document.description = 'Amplify your professional network'

    setTimeout(function () {
      typer1.current.stop();
      setInterval(function () {
        typer1.current.stop();
      }, totalTime);
    }, type1Time);

    setInterval(function () {
      typer1.current.start();
    }, totalTime);

    setTimeout(function () {
      if (placeHolderRef.current) {
        placeHolderRef.current.style.display = "none";
      }
      typer2.current.start();
      setInterval(function () {
        typer2.current.start();
      }, totalTime);
    }, type1Time);

    setInterval(function () {
      typer2.current.stop();
    }, totalTime);
  }, []);

  return (
    <ScrollPositionContext.Provider value={scrollY}>
      <WindowSizeContext.Provider value={windowSize}>
        <ChakraProvider theme={theme}>
          <Box height="100%">
            <Heading
              marginTop={{ sm: "40px", md: "130px", lg: "150px" }}
              fontSize={{ sm: "40px", md: "60px", lg: "80px" }}
            >
              <Box
                d="flex"
                flexDirection={{ base: "row" }}
                justifyContent={{ xl: "center", base: "center", sm: "center" }}
              >
                Introducing&nbsp;
                <Text bgClip="text" bgGradient="linear(to-tl, purple.500, cyan.400)">
                  Level
                </Text>
              </Box>
            </Heading>
            <Heading>
              <Box
                m={["5px", "15px", "20px"]}
                d="flex"
                flexDirection={["row"]}
                justifyContent={["start", "center", "center", "center"]}
              >
                <Box
                  d={{ base: "flex" }}
                  flexDirection={{ sm: "column", md: "row" }}
                  fontSize={{ sm: "20px", md: "25px", lg: "35px" }}
                >
                  <Box d="flex" flexDirection="row">
                    Get&nbsp;
                    <Text bgClip="text" bgGradient="linear(to-tl, purple.500, cyan.400)">
                      <Typed
                        typedRef={(typer) => {
                          typer1.current = typer;
                        }}
                        strings={["sales", "investors", "feedback", "hires"]}
                        typeSpeed={typeSpeed}
                        backSpeed={backSpeed}
                        backDelay={backDelay}
                      />
                    </Text>
                  </Box>
                  <Box d="flex" flexDirection="row" fontSize={{ sm: "16px", md: "25px", lg: "35px" }}>
                    with a little help from your&nbsp;
                    <Text bgClip="text" bgGradient="linear(to-tl, purple.500, cyan.400)">
                      <span ref={placeHolderRef}>friends</span>
                      <Typed
                        typedRef={(typer) => {
                          typer2.current = typer;
                        }}
                        strings={["team", "advisors", "softball team", "friends"]}
                        typeSpeed={typeSpeed}
                        backSpeed={backSpeed}
                        backDelay={backDelay}
                        startDelay={type1Time}
                        stopped={true}
                      />
                    </Text>
                  </Box>
                </Box>
              </Box>
            </Heading>
            <Center margin={{ sm: "20px 20px 10px 20px", md: "60px 20px 20px 20px" }}>
              <EmailSignup
                onSuccess={() => {
                  navigate("?signup");
                }}
              />
            </Center>
            <Center margin={{ sm: "10px 20px 20px 20px", md: "20px 20px 60px 20px" }}>
              <Button
                colorScheme="pink"
                variant="outline"
                fontWeight={400}
                borderRadius={60}
                onClick={onScheduleDemo}
                fontSize={{ sm: "16px", md: "16px", lg: "20px" }}
              >
                Request Demo
              </Button>
            </Center>
            <Center margin={{ sm: "100px 30px 20px 30px", md: "20px 80px" }}>
              <AspectRatio flex="1 1 auto" maxW="800px" ratio={16 / 9}>
                <iframe className="video-wrapper" src="https://www.youtube.com/embed/nYn4iTxvwyw" allowFullScreen />
              </AspectRatio>
            </Center>
            <Box marginTop={["150px", "150px"]}></Box>
            <Footer />
          </Box>
        </ChakraProvider>
      </WindowSizeContext.Provider>
    </ScrollPositionContext.Provider>
  );
}

export default TrustedNetworkLandingPage;
