import React, { useContext, useEffect, useState } from 'react';
import PageHeader from '../components/page-header';
import { streamPrototypes } from '../data/streams';
import SortableTable from '../components/sortable-table';

function ReportsList(props) {

  const accountId = 'none';
  const [ prototypes, updatePrototypes ] = useState([
    {
      id: '1',
      name: 'Google Homepage',
      url: 'google.com',
      lastUpdated: '10-2-2020 at 10:40pm',
    },
    {
      id: '2',
      name: 'Pinterest Search',
      url: 'pinterest.com/?q=stuff',
      lastUpdated: '10-2-2020 at 10:40pm',
    },
  ]);

  useEffect(() => {
    const unsubscribe = streamPrototypes(accountId, {
      next: querySnapshot => {
        const updatedPrototypes = querySnapshot.docs.map(docSnapshot => docSnapshot.data());
        //updatePrototypes(updatedPrototypes);
      },
      error: () => {
        console.error('[Jibe] Failed to fetch prototypes list')
      }
    });
    return unsubscribe;
  }, [accountId, updatePrototypes]);

  return (
    <div style={styles.pageContent}>
      <PageHeader header="Vehicles" />
      <div style={styles.content}>
        <SortableTable columns={[]} data={[]} />
      </div>
    </div>
  );
}

const styles = {
  separator: {
    margin: '5px',
    width: '2px',
    height: '2px',
    borderRadius: '2px',
    backgroundColor: '#E5E5E5',
  },
  tileDetails: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
  },
  tile: {
    borderRadius: '8px',
    border: '1px solid #E5E5E5',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  content: {
    position: 'relative',
  },
  screenshot: {
    background: 'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHJlY3QgZmlsbD0iI0ZGRkZGRiIgd2lkdGg9IjE2IiBoZWlnaHQ9IjE2Ii8+PHBhdGggZmlsbD0iI0YxRjFGMSIgZD0iTSAwIDggSCAxNiBWIDAgSCA4IFYgMTYgSCAwIi8+PC9zdmc+")',
    borderRadius: '8px 8px 0px 0px',
    width: '270px',
    height: '180px',
    position: 'relative',
    flexGrow: '1',
  },
  tileInfo: {
    height: '66px',
  },
  tileName: {
    marginTop: '10px',
    fontWeight: '500',
  },
  tileDetail: {
    color: '#b3b3b3',
    fontSize: '12px',
  },
  tileContentBackground: {
    paddingLeft: '10px',
    backgroundColor: 'rgba(255,255,255,0.5)',
  },
  pageContent: {
    marginLeft: '240px',
  },
}

export default ReportsList;
