import React from 'react';

function Social() {

  return (
    <p className="social" style={styles.social}>
      <a href="mailto:asa.schachar+asametrical@gmail.com"><i className="fas fa-envelope" aria-hidden="true"></i></a>
      <a href="https://www.linkedin.com/in/asaschachar/"><i className="fab fa-linkedin" aria-hidden="true"></i></a>
      <a href="https://github.com/asaschachar"><i className="fab fa-github" aria-hidden="true"></i></a>
      <a href="https://twitter.com/asametrical"><i className="fab fa-twitter" aria-hidden="true"></i></a>
    </p>
  )
}

const styles = {
  social: {
    width: "150px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    color: "#999999"
  }
}

export default Social;
