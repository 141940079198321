import React from 'react'
import styled from 'styled-components'
import { useTable, useSortBy } from 'react-table'
import SentimentEmoji from './sentiment-emoji.js'

const Styles = styled.div`
  display: block;
  max-width: 100%;
  padding: 1.5rem;

  table {
    table-layout: auto;
    border-spacing: 0;
    width: 100%;

    tr {
      border-bottom: 1px solid #f0f0f0;
      font-size: 12px;
      padding: 20px;


      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      font-size: 12px;
      color: #707070;
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    td {
      padding: 1.5rem;
      font-size: 16px;
    }

    th,
    td {
      white-space: nowrap;
      margin: 0;

      &.grow {
        width: 100%;
      }

      :last-child {
        border-right: 0;
      }
    }
  }
`

function Table({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

 return (
   // apply the table props
   <table {...getTableProps()}>
     <thead>
       {// Loop over the header rows
       headerGroups.map(headerGroup => (
         // Apply the header row props
         <tr {...headerGroup.getHeaderGroupProps()}>
           {// Loop over the headers in each row
           headerGroup.headers.map(column => (
             // Apply the header cell props
             <th {...column.getHeaderProps({
               className: column.grow ? 'grow' : '',
             })}>
               {// Render the header
               column.render('Header')}
             </th>
           ))}
         </tr>
       ))}
     </thead>
     {/* Apply the table body props */}
     <tbody {...getTableBodyProps()}>
       {// Loop over the table rows
       rows.map(row => {
         // Prepare the row for display
         prepareRow(row)
         return (
           // Apply the row props
           <tr {...row.getRowProps()}>
             {// Loop over the rows cells
             row.cells.map(cell => {
               // Apply the cell props
               return (
                 <td {...cell.getCellProps({
                   className: cell.column.grow ? 'grow' : '',
                 })}>
                   {// Render the cell contents
                   cell.render('Cell')}
                 </td>
               )
             })}
           </tr>
         )
       })}
     </tbody>
   </table>
 )
}

function SortableTable() {
  const columns = React.useMemo(
    () => [
      {
        Header: 'Status',
        accessor: 'sentiment',
        Cell: props => ( <SentimentEmoji value={props.value} />)
      },
      {
        Header: 'Name',
        accessor: 'name',
        grow: true,
      },
      {
        Header: 'Last Checked',
        accessor: 'last_modified',
        grow: true,
      },
    ],
    []
  )

  const data = React.useMemo(
    () => [
      {
        name: 'Asa Schachar',
        sentiment: 'mad',
        last_modified: 'April 1, 2021',
      },
      {
        name: 'Vik Nguyen',
        sentiment: 'sad',
        last_modified: 'April 4, 2021',
      },
      {
        name: 'Kyle Farewell',
        sentiment: 'happy',
        last_modified: 'April 8, 2021',
      },
    ],
    []
  )

  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  )
}

export default SortableTable
