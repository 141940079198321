import React, { useEffect } from 'react';
import Shelf from '../components/shelf'
import Header from '../components/header'
import AllShelves from '../components/all-shelves'
import Terms from '../components/terms'
import Demo from '../components/demo'
import Footer from '../components/footer'
import useWindowSize from '../helpers/window-size-hook'
import ScrollPositionContext from '../helpers/scroll-position-context'
import useScrollPosition from '../helpers/scroll-position-hook'
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import { GetContent } from '../helpers/content.js'

function LandingPage() {

  const CONTENT = GetContent();
  const scrollY = useScrollPosition();
  const windowSize = useWindowSize();
  const styles = getStyles(getScreenSize(windowSize))

  useEffect(() => {
    document.title = CONTENT['name'];
  }, [])

  return (
    <ScrollPositionContext.Provider value={scrollY}>
      <WindowSizeContext.Provider value={windowSize}>
        <div className="app" style={styles.content}>
          <Header />
          <Terms />
          <Footer />
        </div>
      </WindowSizeContext.Provider>
    </ScrollPositionContext.Provider>
  );
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let styleMods = {};
  let content;
  if (isDesktop) {
    content = {
      margin: '0px auto',
    }
  }

  if (isTablet) {
    content = {
      margin: '0px auto',
    }
  }

  if (isMobile) {
    content = {
      minWidth: '300px',
      margin: '0px auto',
    }
  }

  let styles = {
    content: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      ...content
    },
  }
  return styles;
}

export default LandingPage;
