export function track(event, data) {
  if (window.analytics && window.analytics.track) {
    window.analytics.track(event, data);
  }
}

export function page(name) {
  if (window.analytics && window.analytics.page) {
    window.analytics.page(name);
  }
}

export function identify(id) {
  if (window.analytics && window.analytics.identify) {
    window.analytics.identify(id, {
      email: id,
    });
  }
}


export default {
  track,
  identify,
  page,
}
