import React from 'react';
import '../App.css';
import Shelf from './shelf.js'
import { GetContent } from '../helpers/content.js'

function AllShelves() {

  const CONTENT = GetContent();
  const shelves = CONTENT['features'].map((shelf, index) => {
    shelf.index = index;
    return (
      Shelf(shelf)
    )
  })

  return (
    <div>
      { shelves }
    </div>
  );
}

export default AllShelves;
