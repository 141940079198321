import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Social from './social'
import packageJson from '../../package.json'
import Container from '../components/container';
import { createBreakpoints } from "@chakra-ui/theme-tools";
import { ChakraProvider, Flex, Box, Center, Button, Heading, AspectRatio, Text, VStack, extendTheme } from "@chakra-ui/react";
import { track } from "../helpers/analytics";

const breakpoints = createBreakpoints({
  sm: "320px",
  md: "768px",
  lg: "960px",
  xl: "1200px",
});

function Footer() {
  let email = `hello@trylevel.app`

  let logo = (
    <Text fontSize="3xl">level</Text>
  )

  let copyright = (
    <Text fontSize={{sm: 'xs', md: 'md'}}> © Asabase, Inc 2021. All Rights Reserved.</Text>
  )

  let links = (
    <>
      <a href="/" style={styles.links}>Home</a>
      <a href="/privacy" style={styles.links}>Privacy Policy</a>
      <a href="/terms" style={styles.links}>Terms of Service</a>
      <Flex>
        <span>Contact: </span>
        <img style={{height: '15px', marginLeft: '5px', marginTop: '7px'}} src="/images/email.svg" />
      </Flex>
    </>
  )

  return (
    <Box  position="fixed" bottom="0" width="100%" pl={{sm: "10px", md: "40px", lg: "100px"}} pr={{ sm: "10px", md: "40px", lg: "100px"}}>
      <Box borderTopRightRadius="30px"  borderTopLeftRadius="30px" padding="20px" className="colored-background" >
        <Flex color="white" direction={{ sm: 'column', md: "row"}} justifyContent="space-between">
          <Flex direction="column" justifyContent="space-between" alignItems={{ sm: 'center', md: 'flex-start'}}>
            { logo }
            { copyright }
          </Flex>
          <Flex direction="column" alignItems={{ sm: 'center', md: "flex-end" }}>
            { links }
          </Flex>
        </Flex>
      </Box>
    </Box>
  )
}
const styles =  {
  links: {
    color: 'white',
    fontSize: "14px",
    textDecoration: 'underline',
  },
}

export default Footer;
