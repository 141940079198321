import React, { useContext, useEffect } from 'react';
import { Redirect, navigate } from "@reach/router";
import { AuthContext } from '../helpers/auth-context';
import LoginPage from '../pages/login-page'

function PrivateRoute(props) {
  const { user } = useContext(AuthContext);

  let { as: Comp, ...rest } = props;
  return user
    ? <Comp {...rest} />
    : <LoginPage />;
}

export default PrivateRoute;
