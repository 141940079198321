import { useState, useEffect } from 'react';

function useScrollPosition() {

  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    function handleScroll() {
      setScrollY(window.scrollY);
    }

    // Add event listener
    window.addEventListener("scroll", handleScroll);

    // Call handler right away so state gets updated with initial window size
    handleScroll();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleScroll);

  }, []); // Empty array ensures that effect is only run on mount

  return scrollY;
}

export default useScrollPosition;
