import { useParams } from "@reach/router"
import CONTENT from '../content/content.json'

export const GetContent = () => {
  const params = useParams();
  return CONTENT[params.app] || CONTENT.level;
}

export default {
  GetContent
}
