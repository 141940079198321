import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Social from './social'
import packageJson from '../../package.json'
import Container from '../components/container';
import { GetContent } from '../helpers/content.js'

function Footer() {
  const CONTENT = GetContent();
  let email = `hello@trylevel.app`

  const windowSize = useContext(WindowSizeContext)
  const { isDesktop, isTablet, isMobile } = getScreenSize(windowSize)
  const styles = getStyles({ isDesktop, isTablet, isMobile })

  let logo = (
    <div style={styles.logo}>level</div>
  )

  let copyright = (
    <p style={styles.copyright}> © Asabase, Inc 2021. All Rights Reserved.</p>
  )

  let links = (
    <>
      <a href="/" style={styles.links}>Home</a>
      <a href="/privacy" style={styles.links}>Privacy Policy</a>
      <a href="/terms" style={styles.links}>Terms of Service</a>
      <p style={styles.email}>
        <span>Contact: </span>
        <img style={styles.emailImg} src="/images/email.svg" /> </p>
    </>
  )

  if (isMobile || isTablet) {
    return (
      <div style={styles.footerContainer} className="colored-background">
        <div style={styles.linksContainer}>
          { logo }
          { links }
          { copyright }
        </div>
      </div>
    )
  }

  if (isDesktop) {
    return (
      <div style={styles.footerContainer} className="colored-background">
        <div style={styles.leftSide}>
          { logo }
          { copyright }
        </div>
        <div style={styles.rightSide}>
          { links }
        </div>
      </div>
    )
  }
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let footerContainer, links, logo;

  if (isDesktop) {
    footerContainer = {
      minWidth: '900px',
      padding: '5px 30px',
      margin: '0px 120px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    };
    links = {
      margin: '2px 0px',
    }
    logo = {
      margin: '0px 0px 10px 0px',
    }
  }

  if (isTablet) {
    footerContainer = {
      margin: '0px 60px',
      minWidth: '75%'
    };
    logo = {
      margin: '0px 0px 10px 0px',
    }
  }

  if (isMobile) {
    footerContainer = {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: '0px 30px',
      padding: '10px 40px',
      minWidth: '75%'
    };
  }

  let styles = {
    linksContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      flexDirection: "column",
      width: '100%',
      marginBottom: '10px',
    },
    logo: {
      margin: '10px 0px',
      fontSize: "30px",
      fontWeight: "400",
      ...logo,
    },
    links: {
      margin: '5px 0px',
      color: 'white',
      fontSize: "14px",
      textDecoration: 'underline',
      ...links,
    },
    leftSide: {
      marginRight: '60px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      flexDirection: 'column',
      padding: '15px 0px',
    },
    rightSide: {
      marginLeft: '60px',
      padding: '15px 0px',
      display: 'flex',
      justifyContent: 'space-around',
      alignItems: 'flex-end',
      flexDirection: 'column',
    },
    footerContainer: {
      padding: '15px',
      flexShrink: 0,
      color: 'white',
      borderTopRightRadius: '30px',
      borderTopLeftRadius: '30px',
      display: "flex",
      flexDirection: "column",
      ...footerContainer,
    },
    copyright: {
      margin: '5px 0px',
      fontSize: "14px",
      textAlign: "center",
      width: "100%",
    },
    email: {
      margin: '5px 0px',
      fontSize: '14px',
    },
    emailImg: {
      display: 'inline-block',
      height: '14px',
    },
  }
  return styles;
}

export default Footer;
