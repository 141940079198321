import React, { useContext, useEffect } from 'react';

function PageHeader(props) {

  return (
    <div style={styles.pageHeader}>
      { props.header }
    </div>
  );
}

const styles = {
  pageHeader: {
    fontSize: '20px',
    fontWeight: '600',
    marginTop: '20px',
    paddingLeft: '30px',
    lineHeight: '25px',
    paddingBottom: '20px',
    borderBottom: '1px solid #E5E5E5',
  },
}

export default PageHeader;
