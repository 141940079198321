import React, { useContext, useEffect } from 'react';
import { useParams } from "@reach/router"
import { Redirect, Link, navigate } from "@reach/router";
import { useLocation } from "@reach/router"
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import { AuthContext } from '../helpers/auth-context';
import ReportsList from '../pages/reports-list';
import Settings from '../pages/settings';
import Sidebar from '../components/sidebar';

function Dashboard() {
  const params = useParams();
  let page = params.page;

  let componentMap = {
    'reports': (<ReportsList />),
    'settings': (<Settings />),
  }

  let content = componentMap[page] || componentMap['reports']

  return (
    <div>
      <Sidebar />
      { content }
    </div>
  );
}

const styles = {
}

export default Dashboard;
