import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Button from 'react-bootstrap/Button';
import Social from './social';
import EmailSignup from './email-signup.js';
import Icon from './icon.js';
import IconButton from '@material-ui/core/IconButton';
import { GetContent } from '../helpers/content.js'
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
} from '../styles/global'

function SignUpDialog({ onClose }) {

  const CONTENT = GetContent();
  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  return (
    <div style={styles.dialogContainer}>
      <div style={styles.signUpContainer}>
        <div style={styles.closeButton}>
          <IconButton onClick={onClose}>
            <Icon id='close' />
          </IconButton>
        </div>
        <div style={styles.signUpContent}>
          <h1>{CONTENT['signup']['title'] || 'Sign Up'}</h1>
          <p style={styles.subText}>
            {CONTENT['signup']['subTitle'] || 'Provide your email below to sign up for free!'}
          </p>
          <EmailSignup />
        </div>
      </div>
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {

  let signUpContainer;
  if (isDesktop) {
    signUpContainer = {
      maxWidth: '450px',
    }
  }

  if (isTablet) {
    signUpContainer = {
      maxWidth: '450px',
    }
  }

  if (isMobile) {
    signUpContainer = {
      maxWidth: '95%',
    }
  }

  let styles = {
    subText: {
      textAlign: 'center'
    },
    signUpContainer: {
      zIndex: 5,
      position: 'relative',
      padding: '30px',
      minWidth: '300px',
      background: 'white',
      borderRadius: '10px',
      ...signUpContainer,
    },
    signUpContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
    },
    dialogContainer: {
      zIndex: 5,
      position: 'fixed',
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0,0,0,0.3)',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      left: 0,
      top: 0,
    },
    closeButton: {
      position: 'absolute',
      right: '0px',
      top: '0%',
    },
  }

  return styles;
}

export default SignUpDialog;
