import React, { useState, useContext } from 'react';

function SentimentEmoji(props) {
  let imgMap = {
    'sad':  '/images/sentiments/sad-gif.gif',
    'happy':  '/images/sentiments/happy-gif.gif',
    'mad':  '/images/sentiments/angry-gif.gif',
  }

  return (
    <img width={40} src={imgMap[props.value]} />
  )
}

const styles = {
}

export default SentimentEmoji;
