import React, { useState, useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { Formik } from 'formik';
import { identify, track } from '../helpers/analytics.js';
import { firebase } from '../modules/firebase-module.js'

function EmailSignup({ cta, onSuccess }) {

  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  let [successfulSubmission, setSuccessfulSubmission] = useState(false);

  let onSubmit = (values, { setSubmitting }) => {
    track('Signed Up', {
      email: values.email
    })
    firebase.auth().createUserWithEmailAndPassword(values.email, 'ne3d-t0-ch4nge!')
      .then((userCredential) => {
        //identify(userCredential.user.uid, {
        identify(values.email, {
          email: values.email
        });
        setSubmitting(false);
        setSuccessfulSubmission(true);
        if (onSuccess) onSuccess();
      })
      .catch((error) => {
        setSubmitting(false);
        setSuccessfulSubmission(true);
        track(`Failed Signup`, {
          email: values.email,
          error: error.code,
        })
        if (onSuccess) onSuccess();
      });
  }

  let emailForm = (
    <Formik
      initialValues={{ email: '' }}
      validate={values => {
        const errors = {};
        if (!values.email) {
          errors.email = 'Required';
        } else if (
          !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
        ) {
          errors.email = 'Invalid email address';
        }
        return errors;
      }}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleSubmit,
        handleBlur,
        isSubmitting,
        /* and other goodies */
      }) => (
        <Form
          style={styles.emailInput}
          noValidate
          onSubmit={handleSubmit}>
          <InputGroup className="mb-3">
            <Form.Control
              style={styles.emailTextInput}
              className="email-input"
              isInvalid={errors.email && touched.email}
              required
              type="email"
              name="email"
              onBlur={handleBlur}
              value={values.email}
              placeholder="Email"
              aria-label="Email"
              aria-describedby="basic-addon2"
              onChange={handleChange}
            />
            <InputGroup.Append>
              <Button
                style={styles.emailSignUpButton}
                id="email-signup-button"
                className="email-submit"
                disabled={isSubmitting}
                type="submit"
                variant="dark">
                { cta || 'Join the Waitlist' }
              </Button>
            </InputGroup.Append>
            { !(errors.email && touched.email) && (<div style={styles.errorSpace}></div>) }
            { errors.email && touched.email &&  (
              <Form.Control.Feedback type="invalid" style={styles.errorContainer}>
                <div style={styles.invalidText}> { errors.email } </div>
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form>
      )}
    </Formik>
  )

  let successMessage = (
    <div style={styles.success}>
      { `Great! We'll be in touch shortly ✅` || 'Success! ✅' }
    </div>
  )

  return (
    <div style={styles.emailContainer}>
      { successfulSubmission ? successMessage : emailForm }
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let emailContainer, emailInput, emailTextInput, emailSignUpButton, success;

  if (isDesktop) {
    emailContainer = {
      width: '350px',
    }
    emailTextInput = {
    }
    emailSignUpButton = {
    }
    success = {
      fontSize: '25px',
    }
  }

  if (isTablet) {
    emailContainer = {
      width: '350px',
    }
    emailTextInput = {
      fontSize: '16px',
    }
    emailSignUpButton = {
      fontSize: '16px',
    }
    success = {
      fontSize: '16px',
    }
  }

  if (isMobile) {
    emailContainer = {
      minWidth: '100%',
      width: '325px',
    }
    emailInput = {
      width: '100%',
    }
    emailTextInput = {
      fontSize: '16px',
    }
    emailSignUpButton = {
      fontSize: '16px',
    }
  }

  let styles = {
    success: {
      fontWeight: 600,
      fontSize: '16px',
      color: '#5fb810',
      textAlign: 'center',
      marginBottom: '20px',
      ...success,
    },
    emailContainer: {
      position: 'relative',
      zIndex: 1,
      marginTop: '10px',
    },
    invalidText: {
    },
    errorContainer: {
    },
    errorSpace: {
    },
    emailTextInput: {
      paddingLeft: '20px',
      fontSize: '20px',
      fontFamily: 'inherit',
      borderTopLeftRadius: '20px',
      borderBottomLeftRadius: '20px',
      ...emailTextInput,
    },
    emailInput: {
      width: '450px',
      ...emailInput,
    },
    emailSignUpButton: {
      fontSize: '20px',
      paddingRight: '20px',
      fontFamily: 'MessinaSans, -apple-system, system-ui, "Segoe UI", Roboto, "Helvetica Neue", Ubuntu, sans-serif',
      borderTopRightRadius: '25px',
      borderBottomRightRadius: '25px',
      ...emailSignUpButton,
    },
    formInput: {
      display: 'flex',
      flexDirection: 'column',
    }
  }
  return styles;
}

export default EmailSignup;
