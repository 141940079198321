import React, { useContext } from 'react';
import WindowSizeContext from '../helpers/window-size-context'
import getScreenSize from '../helpers/get-screen-size'
import Social from './social'
import { GetContent } from '../helpers/content.js'
import EmailSignup from './email-signup.js'
import ReactMarkdown from 'react-markdown'
import TermsPolicy from '../content/terms.js'
import Button from 'react-bootstrap/Button';
import {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
  APP_MAX_WIDTH,
} from '../styles/global'
import { track } from '../helpers/analytics'

function Privacy(info) {

  const CONTENT = GetContent();
  const windowSize = useContext(WindowSizeContext)
  const styles = getStyles(getScreenSize(windowSize))

  return (
    <div style={styles.container}>
      <ReactMarkdown children={TermsPolicy}>
      </ReactMarkdown>
    </div>
  )
}

function getStyles({ isDesktop, isTablet, isMobile }) {
  let styleMods = {};
  if (isDesktop) {

  }

  if (isTablet) {

  }

  if (isMobile) {

  }

  let styles = {
    container: {
      margin: '40px',
      flexGrow: 1,
      maxWidth: '1000px',
    }
  }
  return styles;
}

export default Privacy;
