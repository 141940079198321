import React from 'react';

function NotFound() {

  return (
    <div style={styles.container}>
      <div>Not Found</div>
      <div>Sorry there is nothing here</div>
      <div>404</div>
    </div>
  );
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}

export default NotFound;
