import React from 'react';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import Settings from '@material-ui/icons/Settings';
import Cancel from '@material-ui/icons/Cancel';
import ExitToApp from '@material-ui/icons/ExitToApp';
import Person from '@material-ui/icons/Person';

const iconMap = {
  file: (<InsertDriveFileOutlinedIcon />),
  person: (<Person />),
  settings: (<Settings />),
  close: (<Cancel />),
  exit: (<ExitToApp />)
}

function Icon(props) {
  return iconMap[props.id] || null;
}

export default Icon;
