export const APP_PADDING_DESKTOP = '150px'
export const APP_PADDING_TABLET = '50px'
export const APP_PADDING_MOBILE = '25px'
export const APP_MAX_WIDTH = '1280px'

export default {
  APP_PADDING_DESKTOP,
  APP_PADDING_TABLET,
  APP_PADDING_MOBILE,
  APP_MAX_WIDTH,
}
